import React, { useEffect, useState, useMemo } from 'react';
import ArrowUpIcon from 'assets/icons/arrow-up.svg';
import ArrowDownIcon from 'assets/icons/arrow-down.svg';
import DownloadIcon from 'assets/icons/download-icon.svg';
import DownloadIconWhite from 'assets/icons/download-icon-white.svg';
import ViewIcon from 'assets/icons/view-icon.svg';
import VideoIcon from 'assets/icons/video-icon.svg';
import VideoIconWhite from 'assets/icons/video-icon-white.svg';
import DocumentIcon from 'assets/icons/document-icon.svg';
import ImgIcon from 'assets/icons/img-icon.svg';
import ImgIconWhite from 'assets/icons/img-icon-white.svg';
import SettingsIconWhite from 'assets/icons/settings-icon-white.svg';
import SettingsIconBlack from 'assets/icons/settings-icon-black.svg';
import DocumentIconWhite from 'assets/icons/document-icon-white.svg';
import ArrowBackWhite from 'assets/icons/arrow-back-white.svg';
import Modal from 'react-modal';
import navLeft from 'assets/icons/navigate-left.svg';
import navRight from 'assets/icons/navigate-right.svg';
import { MEDIA_TYPES } from 'types';
import { MediaObject } from '../../pages/dashboard/slice/types';
import { Document, Page, pdfjs } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import { translations as _ } from 'locales/translations';
import Vimeo from '@u-wave/react-vimeo';
import Checkbox from '../Form/Checkbox';
import { useFormContext } from 'react-hook-form';
import cx from 'classnames';
import Ellipsis from 'react-ellipsis-component';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

Modal.setAppElement(document.getElementById('root'));

export default function MediaItem(props: any) {
  const { mediaKey, mediaElements, menuDeleteOpen } = props;
  const [isFolderOpen, setIsFolderOpen] = useState(false);
  const [isSubfolderOpen, setIsSubfolderOpen] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [indexNav, setIndexNav] = useState(0);
  const [currentElement, setCurrentElement] = useState<MediaObject | null>(
    null,
  );
  const [numPages, setNumPages] = useState(null);
  const [parentCheck, setParentCheck] = useState(false);
  const [parentSubCheck, setParentSubCheck] = useState(false);
  const { t } = useTranslation();
  const i18nKey = _.productDetail;
  const mediaKeyTranslations = _.mediakey;
  const { register, getValues, setValue } = useFormContext();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function navigateModal(index) {
    setIndexNav(index);
    setCurrentElement(mediaElements.medias[index]);
  }

  function getIDVideo(url) {
    var match = /vimeo.*\/(\d+)/i.exec(url);
    if (match) {
      return match[1];
    } else {
      return -1;
    }
  }

  function showPrev() {
    return indexNav > 0;
  }

  function showNext() {
    return indexNav < mediaElements.medias?.length - 1;
  }

  useEffect(() => {
    if (menuDeleteOpen) {
      setIsFolderOpen(true);
    }
  }, [menuDeleteOpen]);

  function setAll() {
    setParentCheck(!parentCheck);
  }
  function setSubMediaAll(element) {
    setParentSubCheck(!parentSubCheck);
    let newObj = {};
    console.log(getValues('files'));
    if (getValues(`files`)) {
      Object.keys(getValues(`files`)).forEach(d => {
        newObj[d] = getValues(`files`)[d].map((m, i) => {
          if (mediaKey === d) {
            const id = mediaElements.medias[i].id;
            return {
              ...m,
              value: !parentCheck ? `${id}` : false,
            };
          } else {
            return m;
          }
        });
      });
      setValue(`files`, newObj);
    }
  }

  function getImageType(name, isWhite = false) {
    switch (name.split('.').pop().toLowerCase()) {
      case 'pdf':
        return isWhite ? DocumentIconWhite : DocumentIcon;
      case 'jpeg':
      case 'jpg':
      case 'png':
        return isWhite ? ImgIconWhite : ImgIcon;
      case 'exe':
        return isWhite ? SettingsIconWhite : SettingsIconBlack;
      default:
        return isWhite ? VideoIconWhite : VideoIcon;
    }
  }

  function returnExtention(element) {
    return element.split('.').pop().toLowerCase();
  }

  function formatMediaKey(mediaKey) {
    return (
      t(mediaKeyTranslations[mediaKey.toLowerCase()]) ||
      mediaKey.charAt(0) + mediaKey.slice(1).toLowerCase()
    );
  }

  const { innerWidth: width } = window;

  function handleOpenSubfolder(elementId) {
    if (isSubfolderOpen !== elementId) {
      setIsSubfolderOpen(elementId);
    } else {
      setIsSubfolderOpen(undefined);
    }
  }

  return (
    <div className="media-item">
      <div className="media-item-container">
        <div className="media-item-header">
          {menuDeleteOpen && (
            <div className="media-item-header-control">
              <Checkbox
                label={''}
                id={mediaKey}
                onClick={() => setAll()}
                defaultChecked={parentCheck}
              />
            </div>
          )}
          <div className="media-item-header-content">
            <img
              alt="arrow"
              src={isFolderOpen ? ArrowUpIcon : ArrowDownIcon}
              className="arrow"
              onClick={() => setIsFolderOpen(!isFolderOpen)}
            />
            <h4 onClick={() => setIsFolderOpen(!isFolderOpen)}>
              {formatMediaKey(mediaKey)}{' '}
              <span className="count-files">
                {mediaElements.medias?.length ||
                  mediaElements?.subfolder?.length}
              </span>
            </h4>
            <div className="details">
              <p className="zip">.zip</p>
              <p className="files">
                {`${
                  mediaElements.medias?.length ||
                  mediaElements?.subfolder?.length
                } files`}
              </p>
              <a href={mediaElements.archive} download>
                <img
                  alt="download"
                  src={DownloadIcon}
                  className={cx('download', {
                    hidden: mediaKey === MEDIA_TYPES.VIDEO,
                  })}
                />
              </a>
            </div>
          </div>
        </div>
        {isFolderOpen && (
          <>
            {mediaElements?.subfolder?.map((element, index) => {
              console.log('elements', element);
              return (
                <div key={element.code} className="media-item-container-sub">
                  <div className="media-item-header">
                    {menuDeleteOpen && (
                      <div className="media-item-header-control">
                        <Checkbox
                          label={''}
                          id={element.code}
                          onClick={() => setSubMediaAll(element)}
                          defaultChecked={parentCheck}
                        />
                      </div>
                    )}
                    <div className="media-item-header-content-sub">
                      <img
                        alt="arrow"
                        src={
                          isSubfolderOpen === element.id
                            ? ArrowUpIcon
                            : ArrowDownIcon
                        }
                        className="arrow"
                        onClick={() => handleOpenSubfolder(element.id)}
                      />
                      <h4 onClick={() => handleOpenSubfolder(element.id)}>
                        {formatMediaKey(element.code)}
                        <span className="count-files">
                          {element.medias?.length}
                        </span>
                      </h4>
                      <div className="details">
                        <p className="zip">.zip</p>
                        <p className="files">
                          {element.medias?.length}
                          files
                        </p>
                        <a href={element.archive} download>
                          <img
                            alt="download"
                            src={DownloadIcon}
                            className={cx('download', {
                              hidden: mediaKey === MEDIA_TYPES.VIDEO,
                            })}
                          />
                        </a>
                      </div>
                    </div>
                  </div>

                  {isSubfolderOpen === element.id &&
                    element?.medias?.map((subMedia, index) => {
                      console.log('submedia', subMedia);
                      return (
                        <div
                          className="media-item-content"
                          key={'content-' + subMedia.code}
                        >
                          {menuDeleteOpen && (
                            <div className="media-item-content-action">
                              <Checkbox
                                label={''}
                                id={subMedia.id}
                                key={subMedia.code}
                                {...register(
                                  `files.${mediaKey}.${element.code}.${index}.value` as `files.mediakey.index.value`,
                                )}
                                value={subMedia.id}
                              />
                            </div>
                          )}
                          <div className="media-item-content-detail">
                            <img
                              alt="documentType"
                              className="document"
                              src={getImageType(subMedia.name)}
                            />
                            <div className="elipsis">
                              <Ellipsis
                                ellipsis
                                text={subMedia.name}
                                maxLine={2}
                              />
                            </div>
                            {!subMedia.name.includes('.exe') &&
                              returnExtention(subMedia.name) !== 'step' &&
                              mediaKey !== MEDIA_TYPES.VIDEO && (
                                <img
                                  onClick={() => {
                                    setCurrentElement(subMedia);
                                    setIndexNav(index);
                                    setIsOpenModal(true);
                                  }}
                                  alt="download"
                                  src={ViewIcon}
                                  className="viewIcon"
                                />
                              )}
                            {mediaKey !== MEDIA_TYPES.VIDEO ? (
                              <a href={subMedia.link_value} download>
                                <img
                                  alt="download"
                                  className="download"
                                  src={DownloadIcon}
                                />
                              </a>
                            ) : (
                              <a
                                href={subMedia.link_value}
                                target={'_BLANK'}
                                rel="noreferrer"
                                className="link-vimeo-mobile"
                              >
                                <img
                                  alt="link vimeo"
                                  className="link vimeo"
                                  src={ViewIcon}
                                />
                              </a>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              );
            })}

            {mediaElements?.medias?.map((element, index) => {
              return (
                <div
                  className="media-item-content"
                  key={'content-' + element.id}
                >
                  {menuDeleteOpen && (
                    <div className="media-item-content-action">
                      <Checkbox
                        label={''}
                        id={element.id}
                        key={element.id}
                        {...register(
                          `files.${mediaKey}.${index}.value` as `files.mediakey.index.value`,
                        )}
                        value={element.id}
                      />
                    </div>
                  )}
                  <div className="media-item-content-detail">
                    <img
                      alt="documentType"
                      className="document"
                      src={getImageType(element.name)}
                    />
                    <div className="elipsis">
                      <Ellipsis ellipsis text={element.name} maxLine={2} />
                    </div>
                    {!element.name.includes('.exe') &&
                      returnExtention(element.name) !== 'step' &&
                      mediaKey !== MEDIA_TYPES.VIDEO && (
                        <img
                          onClick={() => {
                            setCurrentElement(element);
                            setIndexNav(index);
                            setIsOpenModal(true);
                          }}
                          alt="download"
                          src={ViewIcon}
                          className="viewIcon"
                        />
                      )}
                    {mediaKey !== MEDIA_TYPES.VIDEO && (
                      <a href={element.link_value} download>
                        <img
                          alt="download"
                          className="download"
                          src={DownloadIcon}
                        />
                      </a>
                    )}
                    {mediaKey === MEDIA_TYPES.VIDEO && (
                      <a
                        href={element.link_value}
                        target={'_BLANK'}
                        rel="noreferrer"
                        className="link-vimeo-mobile"
                      >
                        <img
                          alt="link vimeo"
                          className="link vimeo"
                          src={ViewIcon}
                        />
                      </a>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        )}
        {currentElement && (
          <Modal
            isOpen={isOpenModal}
            onRequestClose={() => setIsOpenModal(false)}
            contentLabel="Example Modal"
            className="modalDetail"
            overlayClassName="modalDetail-overlay"
          >
            <div className="modalDetail-north">
              <div className="modalDetail-north-name">
                <img
                  onClick={() => setIsOpenModal(false)}
                  alt="back"
                  src={ArrowBackWhite}
                />
                <img
                  alt="download"
                  id="document"
                  src={getImageType(currentElement?.name, true)}
                />
                {/* {currentElement?.name} ({indexNav + 1}/
                {mediaElements.medias.length})*/}
              </div>
              {mediaKey !== MEDIA_TYPES.VIDEO && (
                <div className="modalDetail-north-action">
                  <a
                    className="modalDetail-north-download"
                    href={currentElement?.link_value}
                    download
                  >
                    <img alt="download" id="download" src={DownloadIconWhite} />{' '}
                    {t(i18nKey.download)}
                  </a>
                </div>
              )}
              {mediaKey === MEDIA_TYPES.VIDEO && (
                <div className="modalDetail-north-action">
                  <a
                    className="modalDetail-north-download"
                    href={currentElement?.link_value}
                    target={'_BLANK'}
                    rel="noreferrer"
                  >
                    <img
                      alt="download"
                      className="video"
                      src={VideoIconWhite}
                    />{' '}
                    {t(i18nKey.vimeo)}
                  </a>
                </div>
              )}
            </div>
            <div
              className={`modalDetail-south ${
                mediaKey === MEDIA_TYPES.PICTURE &&
                returnExtention(currentElement.name) !== 'pdf'
                  ? 'picture'
                  : ''
              }`}
            >
              <div className="modalDetail-south-left">
                {showPrev() && (
                  <img
                    onClick={() => {
                      navigateModal(indexNav - 1);
                    }}
                    alt="left"
                    src={navLeft}
                  />
                )}
              </div>
              <div className="modalDetail-south-middle">
                {mediaKey === MEDIA_TYPES.PICTURE &&
                  returnExtention(currentElement.name) !== 'pdf' && (
                    <img src={currentElement.link_value} alt="" />
                  )}
                {mediaKey !== (MEDIA_TYPES.PICTURE && MEDIA_TYPES.VIDEO) &&
                  returnExtention(currentElement.name) === 'pdf' && (
                    <div>
                      <Document
                        loading={t(i18nKey.loading)}
                        file={currentElement.link_value}
                        onLoadSuccess={onDocumentLoadSuccess}
                        className="doc-pdf-container"
                      >
                        {[...Array(numPages)].map((el, i) => {
                          return (
                            <Page
                              key={el}
                              loading=""
                              pageNumber={i + 1}
                              scale={0.65}
                              width={width * 0.8}
                            />
                          );
                        })}
                      </Document>
                    </div>
                  )}
                {mediaKey === MEDIA_TYPES.VIDEO && (
                  <Vimeo
                    video={getIDVideo(currentElement.link_value)}
                    autoplay
                    height={500}
                    volume={0}
                  />
                )}
              </div>
              <div className="modalDetail-south-right">
                {showNext() && (
                  <img
                    onClick={() => {
                      navigateModal(indexNav + 1);
                    }}
                    alt="left"
                    src={navRight}
                  />
                )}
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}
