import React, { RefCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDashboardSlice } from './slice';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import ProductDisplay from '../../components/ProductDisplay';
import elistairLogo from '../../../assets/images/elistair-logo.svg';
import elistairLogoBlue from '../../../assets/images/mobile-logo-blue.svg';
import closeMenuMobile from '../../../assets/icons/close-menu-mobile.svg';
import { RootState } from 'types';
import { IProduct } from './slice/types';
import CustomHeader from '../../components/CustomHeader';
import { useSwipeable } from 'react-swipeable';
import { useHistory } from 'react-router-dom';
import BulletMobile from '../../components/BulletMobile';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ArrowLeft from 'assets/icons/arrow-left-slider.svg';
import ArrowRight from 'assets/icons/arrow-right-slider.svg';
import cx from 'classnames';
import { gaPageView } from 'utils/ga';
import { selectUserInformationsGet } from '../user-informations/slice/selectors';
import { useUserInformationsSlice } from '../user-informations/slice';

export default function Dashboard() {
  const token = localStorage.getItem('access_token');
  const tabletSize = 1024;
  const { actions } = useDashboardSlice();
  const { actions: actionsUser } = useUserInformationsSlice();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const i18nKey = _.dashboard;
  const history = useHistory();
  const { data: user } = useSelector(selectUserInformationsGet);

  const productList = useSelector(
    (state: RootState) => state.dashboard?.getProducts.data,
  );
  const isLoading = useSelector(
    (state: RootState) => state.dashboard?.getProducts.loading,
  );
  const [showNotice, setShowNotice] = useState(false);
  const [productSelected, setProductSelected] = useState<IProduct>();
  const [productSelectedIndex, setProductSelectedIndex] = useState<number>(0);
  const [availableProduct, setAvailableProduct] = useState<any>(0);
  const { ref } = useSwipeable({
    onSwipedLeft: eventData => {
      if (
        window.innerWidth < tabletSize &&
        window.location.pathname === '/training'
      ) {
        history.push({
          pathname: '/',
        });
      }
    },
    onSwipedRight: eventData => {
      if (window.innerWidth < tabletSize && window.location.pathname === '/') {
        history.push({
          pathname: '/training',
        });
      }
    },
  }) as { ref: RefCallback<Document> };

  useEffect(() => {
    ref(document);
  });

  function prevSlide() {
    setProductSelectedIndex(productSelectedIndex - 1);
    if (availableProduct)
      setProductSelected(availableProduct[productSelectedIndex - 1]);
  }
  function nextSlide() {
    setProductSelectedIndex(productSelectedIndex + 1);
    if (availableProduct)
      setProductSelected(availableProduct[productSelectedIndex + 1]);
  }

  function handleClickNotice() {
    window.localStorage.setItem('hasReadNotice', 'yes');
    setShowNotice(false);
    history.push('/training');
  }

  useEffect(() => {
    gaPageView(window.location.pathname + window.location.search);
    setTimeout(() => {
      // This hides the address bar:
      window.scrollTo(0, 1);
    }, 0);
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(actions.getProducts());
      dispatch(actionsUser.getUserInfos());
      setShowNotice(!(window.localStorage.getItem('hasReadNotice') === 'yes'));
    }
  }, [actions, dispatch, token]);

  useEffect(() => {
    if (productList && productList?.length > 0 && user) {
      let availableProd = productList
        .filter(e => user?.deviceAccess?.includes(e.id.toString()))
        .map((item, index, arr) => arr[arr.length - 1 - index]);
      setAvailableProduct(availableProd);
      if (availableProd) {
        setProductSelected(
          availableProd.filter(p => p?.translations[0]?.name)[0],
        );
      }
    }
  }, [productList, user]);

  return (
    <div className="dashboard">
      <div className="dashboard-intro">
        <div className="dashboard-intro-north">
          <img alt="elistair logo" src={elistairLogo} />
        </div>
        <div className="dashboard-intro-south">
          <h1>{t(i18nKey.title)}</h1>
          <p>{t(i18nKey.subtitle1)}</p>
          <p>{t(i18nKey.subtitle2)}</p>
        </div>
      </div>
      <div className="dashboard-content">
        <div className="dashboard-header">
          <CustomHeader showLink={true} />
        </div>
        {isLoading && <div className="loading">{t(i18nKey.loading)}</div>}
        {productSelected && !isLoading && (
          <>
            <ProductDisplay
              productSelected={productSelected}
              productIndex={productSelectedIndex}
            />
            <div className="product-selector">
              {availableProduct.length > 2 ? (
                <button
                  onClick={prevSlide}
                  className={cx('navigator', {
                    hidden: productSelectedIndex <= 0,
                  })}
                >
                  <img src={ArrowLeft} className="slider-prev" alt="prev" />
                </button>
              ) : null}
              <Carousel
                centerMode={true}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                centerSlidePercentage={availableProduct.length < 3 ? 50 : 33}
                showArrows={false}
                selectedItem={productSelectedIndex}
              >
                {availableProduct.map((product, index) => {
                  return (
                    <div
                      className="product-selector--item"
                      key={'item-' + index}
                      onClick={() => {
                        setProductSelected(product);
                        setProductSelectedIndex(index);
                      }}
                    >
                      <img
                        alt="product visual flat"
                        src={product?.visual_flat}
                        className={
                          productSelected &&
                          productSelected?.product_code === product.product_code
                            ? 'product-display--img current'
                            : 'product-display--img'
                        }
                      />
                      <button
                        className={
                          productSelected &&
                          productSelected?.product_code === product.product_code
                            ? 'current'
                            : ''
                        }
                      >
                        {product.translations[0]?.name}
                      </button>
                    </div>
                  );
                })}
              </Carousel>
              {availableProduct.length > 2 ? (
                <button
                  onClick={nextSlide}
                  className={cx('navigator', {
                    hidden: !(
                      availableProduct?.length &&
                      availableProduct?.length - productSelectedIndex > 1
                    ),
                  })}
                >
                  <img src={ArrowRight} className="slider-prev" alt="prev" />
                </button>
              ) : null}
            </div>
            <BulletMobile indexSelected={1} />
          </>
        )}
      </div>
      {showNotice && (
        <div className="dashboard-notice">
          <div className="dashboard-notice-header">
            <img alt="elistair logo" src={elistairLogoBlue} />
            <img alt="skip" onClick={handleClickNotice} src={closeMenuMobile} />
          </div>
          <div className="dashboard-notice-content">
            <h1>{t(i18nKey.title)}</h1>
            <p>{t(i18nKey.notice)}</p>
            <button onClick={handleClickNotice}>{t(i18nKey.btnNotice)}</button>
          </div>
        </div>
      )}
    </div>
  );
}
