import React from 'react';
import cx from 'classnames';

import ArrowUpIconSmall from 'assets/icons/arrow-up-small.svg';
import ArrowDownIconSmall from 'assets/icons/arrow-down-small.svg';
import DocumentIcon from 'assets/icons/document-icon.svg';
import SettingsIconBlack from 'assets/icons/settings-icon-black.svg';
import VideoIcon from 'assets/icons/video-icon.svg';
import DownloadIcon from 'assets/icons/download-icon.svg';
import deleteIcon from 'assets/icons/menu-delete-normal.svg';
import { MediaObject } from 'app/pages/dashboard/slice/types';

interface MediaFileProps {
  media: any;
  index: number;
  mediaFolder: any;
  mediaFolderCode?: string;
  isDeletingSubFolder?: boolean;
  isDeletingFolder?: boolean;
  setDeletingDocs: (deletingDocs: any) => void;
  deletingDocs: number[];
  changeFileOrder: (
    fileIndex: number,
    media: MediaObject,
    direction: 'up' | 'down',
    subIndex?: number,
  ) => void;
  subIndex?: number;
}

export default function MediaFile({
  media,
  index,
  mediaFolderCode,
  subIndex,
  changeFileOrder,
  deletingDocs,
  setDeletingDocs,
  mediaFolder,
  isDeletingFolder,
  isDeletingSubFolder,
}: MediaFileProps) {
  let mediaLength = mediaFolder?.medias?.length;

  console.log('mediaCode', media);

  return (
    <div
      className={cx('documentation-files-list-item', {
        deleting:
          deletingDocs.includes(media.id) ||
          isDeletingFolder ||
          isDeletingSubFolder,
      })}
      key={'item' + index}
    >
      <div className="documentation-files arrow-container">
        <button
          className={cx('arrow-btn small-btn', {
            disabled: index === 0,
          })}
          onClick={() => changeFileOrder(index, media, 'up', subIndex)}
          type="button"
        >
          <img src={ArrowUpIconSmall} alt="arrow up" />
        </button>
        <button
          className={cx('arrow-btn small-btn', {
            disabled: index + 1 === mediaLength,
          })}
          onClick={() => changeFileOrder(index, media, 'down', subIndex)}
          type="button"
        >
          <img src={ArrowDownIconSmall} alt="arrow down" />
        </button>
      </div>
      {media.name.includes('.exe') ? (
        <img alt="file" src={SettingsIconBlack} className="doc" />
      ) : (
        <img
          alt="file"
          src={
            mediaFolderCode === 'VIDEO' || mediaFolderCode === 'video'
              ? VideoIcon
              : DocumentIcon
          }
          className="doc"
        />
      )}
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {media.name}
      </span>
      <a href={media.link_value} target={'_BLANK'} rel={'noreferrer'}>
        <img alt="file" src={DownloadIcon} className="doc download" />
      </a>
      <div>
        <img
          onClick={() => {
            if (!deletingDocs.filter(e => e === media.id).length) {
              setDeletingDocs([...deletingDocs, media.id]);
            }
          }}
          alt="file"
          src={deleteIcon}
          className="doc pointer trash"
        />
      </div>
    </div>
  );
}
