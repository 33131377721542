import React, { useEffect, useState } from 'react';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSupportSlice } from '../../slice';
import CustomHeader from 'app/components/CustomHeader';
import elistairLogo from 'assets/images/mobile-logo-blue.svg';
import { useParams } from 'react-router-dom';
import { selectJiraRequest } from '../../slice/selectors';
import Loader from 'app/components/Loader';
import AvatarJira from './AvatarJira';
import { selectUserInformationsGet } from 'app/pages/user-informations/slice/selectors';
import { useUserInformationsSlice } from 'app/pages/user-informations/slice';
import PreviewMedia from './PreviewMedia';
import CommentInput from './CommentInput';
import CommentList from './CommentList';
import PreviewAttachment from './PreviewAttachment';

export default function RequestDetail() {
  const i18nKey = _.support;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions: actionsSupport } = useSupportSlice();
  const { actions: actionsUser } = useUserInformationsSlice();
  let { requestId } = useParams<{ requestId: string }>();
  const {
    ticketDetail,
    loading,
    attachment,
    loadingAttachment,
    loadingComment,
    allComments,
  } = useSelector(selectJiraRequest);
  const { data: user } = useSelector(selectUserInformationsGet);
  const [showMoreDetail, setShowMoreDetail] = useState<boolean>(false);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [mediaSelected, setMediaSelected] = useState();

  const [newComment, setNewComment] = useState('');

  useEffect(() => {
    dispatch(actionsSupport.getJiraTicketDetail(requestId));
    dispatch(actionsSupport.getAttachment(requestId));
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(actionsSupport.getJiraTicket(user.email));
    } else {
      dispatch(actionsUser.getUserInfos());
    }
  }, [user]);

  const requestFieldValue = type => {
    let result = ticketDetail?.requestFieldValues?.find(e => {
      return (
        e.fieldId.replaceAll(' ', '').toLowerCase() === type ||
        e.label.replaceAll(' ', '').toLowerCase() === type
      );
    });
    return result.value;
  };

  return (
    <div className="support">
      <div className="support-intro">
        <div className="support-intro-north">
          <img alt="elistair logo" src={elistairLogo} />
        </div>
      </div>
      <div className="support-content">
        <div className="support-header">
          <CustomHeader
            showLink={true}
            hideSearch
            urlBack="/request"
            addBack
            invertedColor
          />
        </div>
        {ticketDetail && !loading ? (
          <div className="support-body">
            <div className="top-part">
              <h2>{requestFieldValue('summary')}</h2>
              <h3>{requestId}</h3>
              <div
                className={`${ticketDetail.currentStatus.status
                  .toLowerCase()
                  .replaceAll(' ', '-')} status`}
              >
                {ticketDetail.currentStatus.status}
              </div>
            </div>
            <div className="bottom-part">
              <div className="left-part">
                <div className="desc-container">
                  <h2 className="category-title">{t(i18nKey.description)}</h2>
                  <p>{requestFieldValue('description')}</p>
                </div>
                {!loadingAttachment ? (
                  attachment.length ? (
                    <div className="container-attachment">
                      <h2 className="category-title">
                        {t(i18nKey.attachement, {
                          number: attachment.length.toString(),
                        })}
                      </h2>
                      <PreviewAttachment
                        attachment={attachment}
                        loadingAttachment={loadingAttachment}
                        setIsOpenPreview={setIsOpenPreview}
                        setMediaSelected={setMediaSelected}
                        type="detailRequest"
                      />
                    </div>
                  ) : null
                ) : (
                  <Loader size={30} />
                )}
                {!showMoreDetail ? (
                  <div className="more-detail-container">
                    <div className="info-detail">
                      <span>{t(_.global.form.companyNameLabel)}</span>
                      <p>{requestFieldValue('companyname')}</p>
                    </div>
                    <div className="info-detail">
                      <span>{t(_.global.form.deviceTypeLabel)}</span>
                      <p>
                        {requestFieldValue('typeofdevice')
                          .map(td => td.value)
                          .join(' / ')}
                      </p>
                    </div>
                    <div className="info-detail">
                      <span>{t(_.global.form.serialNumberLabel)}</span>
                      <p>{requestFieldValue('serialnumber')}</p>
                    </div>
                    {requestFieldValue('secondserialnumber') ? (
                      <div className="info-detail">
                        <span>{t(_.global.form.secondSerialNumberLabel)}</span>
                        <p>{requestFieldValue('secondserialnumber')}</p>
                      </div>
                    ) : null}
                    {requestFieldValue('thirdserialnumber') ? (
                      <div className="info-detail">
                        <span>{t(_.global.form.thirdSerialNumberLabel)}</span>
                        <p>{requestFieldValue('thirdserialnumber')}</p>
                      </div>
                    ) : null}
                    {requestFieldValue('eventdate') ? (
                      <div className="info-detail">
                        <span>{t(_.global.form.eventDateLabel)}</span>
                        <p>
                          {new Date(
                            requestFieldValue('eventdate'),
                          ).toLocaleDateString()}
                        </p>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <button
                  className="button-more"
                  onClick={() => setShowMoreDetail(!showMoreDetail)}
                >
                  {showMoreDetail
                    ? t(i18nKey.ctaMoreDetail)
                    : t(i18nKey.ctaLessDetail)}
                </button>
                {!loadingComment ? (
                  allComments.length ? (
                    <CommentList />
                  ) : null
                ) : (
                  <div className="center-loading">
                    <Loader size={30} />
                  </div>
                )}
                <div className="separator" />
                <CommentInput
                  setNewComment={setNewComment}
                  newComment={newComment}
                />
              </div>

              <div className="right-part">
                <span
                  className={`priority ${requestFieldValue(
                    'priority',
                  ).name.toLowerCase()}`}
                >
                  {requestFieldValue('priority').name}
                </span>
                <span className="info-ticket">
                  {t(i18nKey.requester)}
                  <span>
                    <AvatarJira
                      name={`${user?.firstname} ${user?.lastname}`}
                      type="user"
                    />
                    {`${user?.firstname} ${user?.lastname}`}
                  </span>
                </span>
                <span className="info-ticket">
                  {t(i18nKey.updateDate)}
                  <p>
                    {new Date(
                      ticketDetail.currentStatus.statusDate.jira,
                    ).toLocaleDateString()}
                  </p>
                </span>
              </div>
            </div>
          </div>
        ) : (
          <Loader size={20} />
        )}
      </div>
      <PreviewMedia
        currentElement={mediaSelected}
        isOpen={isOpenPreview}
        setIsOpen={setIsOpenPreview}
      />
    </div>
  );
}
