import { useRef, useState } from 'react';
import React from 'react';
import logoutIcon from 'assets/icons/logout-icon.svg';
import AddProductImg from 'assets/icons/add-product.svg';
import SettingsWhiteIcon from 'assets/icons/settings-icon-white.svg';
import SettingsBlackIcon from 'assets/icons/settings-icon-black.svg';
import UserInfos from 'assets/icons/menu-info.svg';
import AddUserIcon from 'assets/icons/add-user.svg';
import ListUserIcon from 'assets/icons/list-users.svg';
import cx from 'classnames';

import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import MenuItem from './MenuItem';
import { selectSessionUserRole } from 'app/slice/selectors';
import { useSelector } from 'react-redux';
import { E_USER_ROLE } from 'types';
import { useLocation } from 'react-router-dom';
import { getColor } from '../utils';
import { useOnClickOutside } from 'usehooks-ts';

const MENU_ITEMS = [
  {
    logo: AddProductImg,
    titleKey: 'addProduct',
    to: '/product-edit',
    isAdmin: true,
    isComm: false,
    newProduct: true,
  },
  {
    logo: AddUserIcon,
    titleKey: 'addUser',
    to: '/add-user',
    isComm: true,
    isAdmin: true,
  },
  {
    logo: ListUserIcon,
    titleKey: 'listUsers',
    to: '/list-user',
    isAdmin: true,
    isComm: true,
  },
  {
    logo: UserInfos,
    titleKey: 'informations',
    to: '/user-informations',
    isAll: true,
  },
];

interface ISettingsBtnProps {
  isAdminView?: boolean;
  invertedColors?: boolean;
}

export default function SettingsBtn({
  isAdminView = false,
  invertedColors = false,
}: ISettingsBtnProps) {
  const refSetting = useRef(null);
  const { t } = useTranslation();
  const i18nKey = _.menu;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { instance } = useMsal();

  const role = useSelector(selectSessionUserRole);

  function handleLogout() {
    instance.logoutPopup().catch(e => {
      console.error(e);
    });
  }

  useOnClickOutside(refSetting, () => setIsMenuOpen(false));

  let location = useLocation();
  let color = getColor(location.pathname);

  let settingsIcon = isMenuOpen ? SettingsWhiteIcon : SettingsBlackIcon;
  if (invertedColors || color === 'white') {
    settingsIcon = isMenuOpen ? SettingsBlackIcon : SettingsWhiteIcon;
  }
  return (
    <div className={cx('settingsbtn', { inverted: invertedColors })}>
      <button
        className={`icon ${isMenuOpen ? 'open' : 'closed'}`}
        onClick={e => {
          e.preventDefault();
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        <img alt="settings" src={settingsIcon} />
      </button>
      {isMenuOpen && (
        <div className="menu" ref={refSetting}>
          {MENU_ITEMS.map((item, index) => (
            <MenuItem
              key={index}
              logo={item.logo}
              titleKey={item.titleKey}
              to={item.to}
              show={
                (item.isAdmin && role === E_USER_ROLE.ADMIN) ||
                (item.isComm && role === E_USER_ROLE.COMM) ||
                item.isAll === true
              }
              newProduct={item.newProduct}
            />
          ))}
          <button onClick={handleLogout} className="option">
            <img alt="logout" src={logoutIcon} />
            <p>{t(i18nKey.logout)}</p>
          </button>
        </div>
      )}
    </div>
  );
}
