import { RootState } from './RootState';

export type { RootState };

export enum E_USER_ROLE {
  CLIENT = 'client',
  ADMIN = 'admin',
  COMM = 'comm',
  INTEGRATOR = 'integrator',
  INTERNE = 'interne',
}

export enum MEDIA_TYPES {
  PICTURE = 'PICTURE',
  USER_MANUAL = 'USER_MANUAL',
  OTHER = 'OTHER',
  USECASE = 'USECASE',
  SPEC_SHEET = 'SPEC_SHEET',
  LEAFLET = 'LEAFLET',
  VIDEO = 'VIDEO',
}
