/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import Login from './pages/public/login';
import Dashboard from './pages/dashboard';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import { useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useConnexionHandle } from 'utils/hooks/useConnexionHandle';
import ProductDetail from './pages/dashboard/product-detail';
import Training from './pages/training';
import Search from './pages/search';
import ProductEdit from './pages/admin/product-edit';
import { ToastContainer } from 'react-toastify';
import { useEffect } from 'react';

import AddUser from './pages/admin/add-user';
import EditUser from './pages/admin/edit-user';
import ListUser from './pages/admin/list-user';
import UserInformations from './pages/user-informations';
import { useDispatch } from 'react-redux';
import { useAppSlice } from './slice';
import CookieConsent from 'react-cookie-consent';
import { initializeGA } from 'utils/ga';
import FAQ from './pages/support/faq';
import Request from './pages/support/request';
import Article from './pages/support/faq/components/Article';
import RequestDetail from './pages/support/request/components/RequestDetail';
import { useMatamo } from '../utils/hooks/useMatamo';

export function App() {
  const { i18n } = useTranslation();
  const { accessToken, inProgress } = useConnexionHandle();
  const isConnected = useIsAuthenticated();
  const dispatch = useDispatch();
  const { actions } = useAppSlice();
  const [isAcceptedCookies, setIsAcceptedCookies] = useState(false);
  useMatamo();

  useEffect(() => {
    if (isAcceptedCookies) {
      initializeGA();
    }
  }, [isAcceptedCookies]);

  useEffect(() => {
    if (isConnected && accessToken && localStorage.getItem('access_token')) {
      dispatch(actions.getSession());
    }
  }, [isConnected, accessToken, localStorage.getItem('access_token')]);

  if (inProgress !== InteractionStatus.None) {
    return <p>Chargement en cours...</p>;
  } else if (accessToken) {
    localStorage.setItem('access_token', accessToken);
  }

  return (
    <>
      <BrowserRouter>
        <Helmet
          titleTemplate="%s - Portail"
          defaultTitle="Portail Elistair"
          htmlAttributes={{ lang: i18n.language }}
        >
          <meta name="description" content="L'application portail d'Elistair" />
        </Helmet>

        <Switch>
          <Route exact path="/" component={isConnected ? Dashboard : Login} />
          <Route
            exact
            path="/training"
            component={isConnected ? Training : Login}
          />
          <Route
            exact
            path="/product-detail/:id"
            component={isConnected ? ProductDetail : Login}
          />
          <Route
            exact
            path="/product-edit"
            component={isConnected ? ProductEdit : Login}
          />
          <Route
            exact
            path="/add-user"
            component={isConnected ? AddUser : Login}
          />
          <Route
            exact
            path="/edit-user"
            component={isConnected ? EditUser : Login}
          />
          <Route
            exact
            path="/list-user"
            component={isConnected ? ListUser : Login}
          />
          <Route
            exact
            path="/user-informations"
            component={isConnected ? UserInformations : Login}
          />
          <Route
            exact
            path="/search"
            component={isConnected ? Search : Login}
          />
          <Route
            exact
            path="/request"
            component={isConnected ? Request : Login}
          />
          <Route
            exact
            path="/request/detail/:requestId"
            component={isConnected ? RequestDetail : Login}
          />
          <Route exact path="/faq" component={isConnected ? FAQ : Login} />
          <Route
            exact
            path="/article"
            component={isConnected ? Article : Login}
          />
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
      <CookieConsent
        disableStyles
        enableDeclineButton
        onDecline={() => setIsAcceptedCookies(false)}
        onAccept={() => setIsAcceptedCookies(true)}
        buttonClasses="b-btn"
        declineButtonClasses="b-btn decline"
        containerClasses="cookie-consent"
        contentClasses="content"
        buttonWrapperClasses="button-wrapper"
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
    </>
  );
}
