import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDashboardSlice } from '../slice';
import { E_USER_ROLE, RootState } from 'types';
import elistairLogo from '../../../../assets/icons/elistair-logo-small.svg';
import navRight from '../../../../assets/icons/navigate-right.svg';
import navLeft from '../../../../assets/icons/navigate-left.svg';
import navRightMobile from '../../../../assets/icons/navigate-right-mobile.svg';
import navLeftMobile from '../../../../assets/icons/navigate-left-mobile.svg';
import brushIcon from '../../../../assets/icons/brush.svg';
import CustomHeader from '../../../components/CustomHeader';
import MediaItem from '../../../components/MediaItem';
import { useHistory, useLocation } from 'react-router-dom';
import { IProduct } from '../slice/types';
import { translations as _ } from '../../../../locales/translations';
import { useTranslation } from 'react-i18next';
import Ellipsis from 'react-ellipsis-component';
import menuDelete from '../../../../assets/icons/menu-delete.svg';
import menuEdit from '../../../../assets/icons/menu-edit.svg';
import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import {
  selectDeleteMedia,
  selectGuidelineURL,
  selectProductDetail,
} from '../slice/selectors';
import { toast } from 'react-toastify';
import DownloadIcon from '../../../../assets/icons/download-icon-white.svg';
import ViewIcon from 'assets/icons/view-icon-white.svg';
import ReactGA from 'react-ga4';
import { selectSessionUserRole } from 'app/slice/selectors';
import { gaPageView } from 'utils/ga';

export default function ProductDetail(props: any) {
  const { t } = useTranslation();
  const i18nKey = _.productDetail;
  const { actions } = useDashboardSlice();
  const dispatch = useDispatch();
  const location = useLocation<{ productCode: string }>();

  const [currentProductPosition, setCurrentProductPosition] = useState<
    number
  >();
  const [currentProductCode, setCurrentProductCode] = useState<string>(
    location.state.productCode,
  );
  const [prevProduct, setPrevProduct] = useState<IProduct>();
  const [nextProduct, setNextProduct] = useState<IProduct>();
  const [hasOverflow, setHasOverflow] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const history = useHistory();
  const [isMenuDeleteOpen, setIsMenuDeleteOpen] = useState(false);
  const [countItems, setCountItems] = useState<any>(0);

  const role = useSelector(selectSessionUserRole);
  const productList = useSelector(
    (state: RootState) => state.dashboard?.getProducts.data,
  );

  let { data: productDetail, loading: productDetailLoading } = useSelector(
    selectProductDetail,
  );
  let { data: guideline, loading: guidelineUrlLoading } = useSelector(
    selectGuidelineURL,
  );

  let { success: mediaDeletedSuccess, loading: mediaLoading } = useSelector(
    selectDeleteMedia,
  );

  const methods = useForm({
    mode: 'onSubmit',
  });

  const { handleSubmit, watch, setValue, getValues } = methods;

  const watchAllFields = watch();

  useEffect(() => {
    if (Object.keys(watchAllFields).length) {
      setCountItems(
        Object.keys(watchAllFields.files)
          .map(o => watchAllFields.files[o].filter(v => !!v.value).length)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
      );
    }
  }, [watchAllFields]);

  useEffect(() => {
    dispatch(actions.getProducts());
    gaPageView(window.location.pathname + window.location.search);
  }, [actions, dispatch]);

  function resetForm() {
    setIsMenuDeleteOpen(false);
  }
  function onSubmit(data) {
    if (Object.keys(watchAllFields).length && productDetail?.id) {
      const idsToBeDeleted = Object.keys(watchAllFields.files)
        .map(o =>
          watchAllFields.files[o]
            .filter(v => !!v.value)
            .map(a => a.value)
            .reduce((acc, current) => {
              acc.push(current);
              return acc;
            }, []),
        )
        .flat(1)
        .map(n => +n);

      dispatch(
        actions.deleteMedia({
          productId: productDetail?.id.toString(),
          ids: idsToBeDeleted,
        }),
      );
    }
  }

  function getProductDetail(productCode) {
    console.log('product ia', productCode);
    dispatch(actions.getProductDetail(productCode));
    setCurrentProductCode(productCode);
    setCurrentProductPosition(getProductIndex(productCode));
  }

  useEffect(() => {
    dispatch(actions.getGuidelineURL());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, dispatch]);

  useEffect(() => {
    getProductDetail(currentProductCode);
    ReactGA.event({
      category: 'product detail view',
      action: 'view : ' + currentProductCode,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, dispatch, currentProductCode]);

  useEffect(() => {
    if (mediaDeletedSuccess) {
      toast.info(t(i18nKey.deletedFiles));
      dispatch(actions.resetFilesDeleted());
      getProductDetail(currentProductCode);
      setIsMenuDeleteOpen(false);

      if (getValues(`files`)) {
        let newObj = {};
        Object.keys(getValues(`files`)).forEach(d => {
          newObj[d] = getValues(`files`)[d].map(m => {
            return {
              ...m,
              value: false,
            };
          });
        });
        setValue(`files`, newObj);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaDeletedSuccess]);

  useEffect(() => {
    if (!currentProductPosition) {
      setCurrentProductPosition(getProductIndex(currentProductCode));
    }

    if (productList && currentProductPosition !== undefined) {
      setPrevProduct(productList[currentProductPosition - 1]);
      setNextProduct(productList[currentProductPosition + 1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProductPosition, productList]);

  useEffect(() => {
    const handleBackButton = event => {
      if (window.location.pathname === '/product-detail/:id') {
        history.push('/');
      }
    };
    window.onpopstate = handleBackButton;

    return () => {
      window.onpopstate = null;
    };
  }, [history]);

  function getProductIndex(productCode) {
    return productList?.findIndex(obj => obj.product_code === productCode);
  }

  function onDescriptionClick() {
    if (hasOverflow || isDescriptionOpen) {
      setIsDescriptionOpen(!isDescriptionOpen);
    }
  }

  return (
    <div className="product-detail">
      <FormProvider {...methods}>
        <form className="form-acs" onSubmit={handleSubmit(onSubmit)}>
          <div className="product-detail-intro">
            <CustomHeader addBack showLink={true} />
            <div className="product-detail-intro-north">
              <img alt="elistair-logo" src={elistairLogo} className="logo" />
              {!productDetailLoading && (
                <>
                  <h1>
                    {productDetail?.translations[0]?.name ||
                      productDetail?.product_code}
                  </h1>
                  <div className="container-3d">
                    <img
                      alt="product-3d"
                      src={productDetail?.visual_3d}
                      className="image"
                    />
                  </div>

                  <h2>{productDetail?.translations[0]?.subtitle}</h2>
                  <p>{productDetail?.translations[0]?.description}</p>
                </>
              )}
            </div>
            {productList && (
              <div className="product-detail-intro-south">
                <div
                  className={cx('navigation', {
                    hidden: !prevProduct,
                  })}
                  onClick={() => getProductDetail(prevProduct?.product_code)}
                >
                  <img alt="left" src={navLeft}></img>
                  <p>{prevProduct?.translations[0]?.name}</p>
                </div>
                <div
                  className={cx('navigation', {
                    hidden: !nextProduct,
                  })}
                  onClick={() => getProductDetail(nextProduct?.product_code)}
                >
                  <img alt="right" src={navRight}></img>
                  <p>{nextProduct?.translations[0]?.name}</p>
                </div>
              </div>
            )}
            <div className="product-detail-intro-mobile">
              <div className="product-detail-intro-mobile-north">
                <img
                  alt="product-viual-3d"
                  src={productDetail?.visual_3d}
                  className="image"
                />
              </div>
              <div className="product-detail-intro-mobile-middle">
                <div
                  className={cx('navigation', {
                    hidden: !prevProduct,
                  })}
                  onClick={() => getProductDetail(prevProduct?.product_code)}
                >
                  <img alt="left" src={navLeftMobile}></img>
                </div>
                <h1>
                  {productDetail?.translations[0]?.name ||
                    productDetail?.product_code}
                </h1>
                <div
                  className={cx('navigation', {
                    hidden: !nextProduct,
                  })}
                  onClick={() => getProductDetail(nextProduct?.product_code)}
                >
                  <img alt="right" src={navRightMobile}></img>
                </div>
              </div>
              <div className="product-detail-intro-mobile-south">
                <h2>{productDetail?.translations[0]?.subtitle}</h2>

                <div className="description" onClick={onDescriptionClick}>
                  <Ellipsis
                    onReflow={ellipsis => setHasOverflow(ellipsis)}
                    ellipsis
                    text={productDetail?.translations[0]?.description || ''}
                    maxLine={isDescriptionOpen ? 100 : 3}
                  />
                  {hasOverflow || isDescriptionOpen ? (
                    <span className="ellipsis">
                      {isDescriptionOpen ? t(i18nKey.less) : t(i18nKey.more)}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx('product-detail-content', {
              isMenuDeleteOpen: isMenuDeleteOpen && countItems,
            })}
          >
            <div className="product-detail-header">
              <CustomHeader addBack urlBack={'/'} showLink={true} />
            </div>
            <div className="product-detail-subheader">
              <h2>Documentation</h2>
              {role === E_USER_ROLE.ADMIN ? (
                <div className="product-detail-subheader-control">
                  <button
                    className={`product-detail-subheader-control-delete`}
                    onClick={e => {
                      e.preventDefault();
                      setIsMenuDeleteOpen(!isMenuDeleteOpen);
                    }}
                  >
                    <img alt="delete" src={menuDelete} />
                  </button>
                  <button
                    className={'product-detail-subheader-control-edit'}
                    onClick={e => {
                      if (productDetail) {
                        dispatch(actions.fillProductInEdit(productDetail));
                        history.push('/product-edit');
                        e.preventDefault();
                      }
                    }}
                  >
                    <img alt="edit" src={menuEdit} />
                  </button>
                </div>
              ) : null}
            </div>
            {productDetail?.mediaFolders.map((mediaFolder, id) => {
              return (
                <MediaItem
                  key={mediaFolder.code}
                  mediaKey={mediaFolder.code}
                  mediaElements={mediaFolder}
                  menuDeleteOpen={isMenuDeleteOpen}
                />
              );
            })}

            {!guidelineUrlLoading && guideline?.url && (
              <div className="media-item product-detail-styles">
                <div className="media-item-container">
                  <div className="media-item-header">
                    <div className="media-item-header-content">
                      <img alt="styles" className="brush" src={brushIcon} />
                      <h4> {t(i18nKey.guideline)}</h4>
                      <a
                        href={guideline.url}
                        target={'_BLANK'}
                        rel="noreferrer"
                      >
                        <img
                          alt="view guideline"
                          src={ViewIcon}
                          className="download view"
                        />
                      </a>
                      <a
                        href={guideline.url}
                        target={'_BLANK'}
                        rel="noreferrer"
                        download
                      >
                        <img
                          alt="download guideline"
                          src={DownloadIcon}
                          className="download"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {!mediaLoading && isMenuDeleteOpen && countItems ? (
            <div className="product-detail-action">
              {countItems ? (
                <div className="product-detail-action-item">
                  {countItems} {t(i18nKey.item)}
                </div>
              ) : (
                ''
              )}
              <div
                className="product-detail-action-cancel"
                onClick={() => resetForm()}
              >
                {t(i18nKey.cancel)}
              </div>
              <div className="product-detail-action-delete">
                <button type="submit">{t(i18nKey.delete)}</button>
              </div>
            </div>
          ) : (
            ''
          )}
          {mediaLoading && isMenuDeleteOpen && (
            <div className="product-detail-action">{t(i18nKey.deleting)}</div>
          )}
        </form>
      </FormProvider>
    </div>
  );
}
