import ReactGA from 'react-ga4';

export const initializeGA = (): void => {
  ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS}`, {});
};

// custom pageview with the location from react router
export const gaPageView = (path: string) => {
  console.log('sending page view : ', path);
  return ReactGA.send({ hitType: 'pageview', page: path });
};

// custom event with label being an optional parameter
export const gaCustomEvent = (category: string, action: string, label = '') => {
  return ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
