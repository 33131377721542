import React, { useEffect, useState } from 'react';
import Logo from 'assets/images/mobile-logo-blue.svg';
import ArrowBack from 'assets/icons/arrow-back.svg';
import DownloadIcon from 'assets/icons/download-icon.svg';
import DocumentIconWhite from 'assets/icons/document-icon-white.svg';
import ViewIcon from 'assets/icons/view-icon.svg';
import { useHistory } from 'react-router';
import SettingsBtn from 'app/components/SettingsBtn';
import { useUserInformationsSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserEstimate,
  selectUserInformationsGet,
} from './slice/selectors';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { gaPageView } from 'utils/ga';
import { Document, Page } from 'react-pdf';
import ArrowBackWhite from 'assets/icons/arrow-back-white.svg';
import Modal from 'react-modal';
import Loader from 'app/components/Loader';
import CustomHeader from '../../components/CustomHeader';

function UserInfo({
  title,
  value,
}: {
  title: string;
  value?: string | string[];
}) {
  return (
    <div className="information">
      <span className="title">{title}: </span>
      {value ? (
        <span className="value">
          {Array.isArray(value) ? value.join(', ') : value}
        </span>
      ) : (
        ' - '
      )}
    </div>
  );
}

export default function UserInformations() {
  const navigate = useHistory().push;
  const { actions } = useUserInformationsSlice();
  const dispatch = useDispatch();
  const { data: user } = useSelector(selectUserInformationsGet);
  const { data: estimates, loading } = useSelector(selectUserEstimate);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [currentElement, setCurrentElement] = useState<any | null>(null);
  const { t } = useTranslation();
  const i18nKey = _.userInfos;

  useEffect(() => {
    gaPageView(window.location.pathname + window.location.search);
    dispatch(actions.getUserInfos());
  }, []);

  useEffect(() => {
    if (user?.crmId) dispatch(actions.getUserEstimates(user.crmId));
  }, [user]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  const { innerWidth: width } = window;

  return (
    <div className="user-informations">
      <div className="header">
        <img src={Logo} className="logo" alt="elistair-logo" />
        <SettingsBtn invertedColors isAdminView />
      </div>
      <div className="content">
        <button className="back-button" onClick={() => navigate('/')}>
          <img alt="back" src={ArrowBack} />
          <span>BACK</span>
        </button>
        <h2 className="page-title">{t(i18nKey.title)}</h2>
        <div className="inner-content">
          <h3 className="inner-content-header">{t(i18nKey.infoTitle)}</h3>
          {user ? (
            <div className="informations">
              <UserInfo
                title={t(i18nKey.companyName)}
                value={user.companyName}
              />
              <UserInfo title={t(i18nKey.firstname)} value={user.firstname} />
              <UserInfo title={t(i18nKey.lastname)} value={user.lastname} />
              <UserInfo title={t(i18nKey.email)} value={user.email} />
            </div>
          ) : null}
        </div>
        {estimates?.length && !loading && user?.quote ? (
          <div className="inner-content">
            <h3 className="inner-content-header">{t(i18nKey.quoteTitle)}</h3>
            <div className="description">{t(i18nKey.quoteDesc)}</div>
            <div className="quote-content">
              {estimates.map(e => {
                const url = window.URL.createObjectURL(
                  new Blob([new Uint8Array(e.attach.file.data).buffer], {
                    type: 'application/json',
                  }),
                );
                return (
                  <div className="quote-item">
                    <span className="id">{`#${e?.id}`}</span>
                    <span className="date">{e?.Valid_Till || '-'}</span>
                    <span className="price">
                      {`${numberWithSpaces(e.Grand_Total)}.00€`}
                    </span>
                    <img
                      onClick={() => {
                        setCurrentElement(e.attach);
                        setIsOpenModal(true);
                      }}
                      alt="download"
                      src={ViewIcon}
                      className="viewIcon"
                    />
                    <a href={url} download={e.attach.data.File_Name}>
                      <img
                        alt="download"
                        className="download"
                        src={DownloadIcon}
                      />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        ) : loading ? (
          <div className="inner-content">
            <Loader size={20} />
          </div>
        ) : null}
      </div>
      {currentElement && (
        <Modal
          isOpen={isOpenModal}
          onRequestClose={() => setIsOpenModal(false)}
          contentLabel="Example Modal"
          className="modalDetail"
          overlayClassName="modalDetail-overlay"
        >
          <div className="modalDetail-north">
            <div className="modalDetail-north-name">
              <img
                onClick={() => setIsOpenModal(false)}
                alt="back"
                src={ArrowBackWhite}
              />
              <img alt="download" id="document" src={DocumentIconWhite} />
              {currentElement.data.File_Name}
            </div>
          </div>
          <div className="modalDetail-south-middle">
            <Document
              loading={'load'}
              file={currentElement.file}
              onLoadSuccess={onDocumentLoadSuccess}
              className="doc-pdf-container"
            >
              {[...Array(numPages)].map((el, i) => {
                return (
                  <Page
                    key={'page' + i}
                    loading=""
                    pageNumber={i + 1}
                    scale={0.65}
                    width={width * 0.8}
                  />
                );
              })}
            </Document>
          </div>
        </Modal>
      )}
    </div>
  );
}
