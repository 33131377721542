import React, { useEffect, useState } from 'react';
import { translations as _ } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAddUserSlice } from '../../add-user/slice';
import { Controller, useForm } from 'react-hook-form';
import { selectAddUserGroups } from '../../add-user/slice/selectors';
import SelectFilter from './SelectFilter';
import MultiSelectFilter from './MultiSelectFilter';
import DatePicker from './DatePicker';

const moodleFilter = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

interface IFilterUsers {
  filterActive: any[];
  setFilterActive: (value: any) => void;
}
export default function FilterUsers({
  filterActive,
  setFilterActive,
}: IFilterUsers) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions: addUserAction } = useAddUserSlice();
  const { data: groups } = useSelector(selectAddUserGroups);
  const { control } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const [selectedGroups, setSelectedGroups] = useState(
    groups?.map(g => ({
      label: g.displayName,
      value: g.id,
      checked: false,
    })),
  );
  const [selectedMoodle, setMoodle] = useState<
    { value: boolean; label: string } | undefined
  >();
  const [selectedDateCreate, setSelectedDateCreate] = useState<
    string[] | undefined
  >();
  const [selectedDateConnect, setSelectedDateConnect] = useState<
    string[] | undefined
  >();

  useEffect(() => {
    dispatch(addUserAction.getGroups());
  }, []);

  useEffect(() => {
    let newFilter: any[] = [];
    if (selectedMoodle) {
      newFilter = [selectedMoodle];
    }
    let selected = selectedGroups?.filter(e => e.checked);
    if (selected?.length) {
      newFilter = newFilter.concat(selected);
    }
    if (selectedDateCreate) {
      let createDateValue = {
        value: `Creation Date ${selectedDateCreate[0]}/${selectedDateCreate[1]}`,
        label: `Creation : from ${
          selectedDateCreate[0].toLocaleString().split(' ')[0]
        } to ${selectedDateCreate[1].toLocaleString().split(' ')[0]}`,
      };
      newFilter = [...newFilter, createDateValue];
    }
    if (selectedDateConnect) {
      let filterActive = {
        value: `Last connection Date ${selectedDateConnect[0]}/${selectedDateConnect[1]}`,
        label:
          selectedDateConnect[0] !== 'NEVER'
            ? `Last connection : from ${
                selectedDateConnect[0].toLocaleString().split(' ')[0]
              } to ${selectedDateConnect[1].toLocaleString().split(' ')[0]}`
            : `Last connection : NEVER`,
      };
      newFilter = [...newFilter, filterActive];
    }
    setFilterActive(newFilter);
  }, [selectedGroups, selectedMoodle, selectedDateCreate, selectedDateConnect]);

  function handleRemoveFilter(filter) {
    if (filter?.label?.includes('Yes') || filter?.label?.includes('No')) {
      setMoodle(undefined);
    }
    if (filter?.checked !== undefined) {
      const updatedOptions = selectedGroups?.map(opt =>
        opt.value === filter.value ? { ...opt, checked: !opt.checked } : opt,
      );
      setSelectedGroups(updatedOptions);
    }
    if (filter?.label?.includes('Creation')) {
      setSelectedDateCreate(undefined);
    }
    if (filter?.label?.includes('Last connection')) {
      setSelectedDateConnect(undefined);
    }
    let newFilter = filterActive.filter(f => f.label !== filter.label);
    setFilterActive(newFilter);
  }

  return (
    <div className="filter-open">
      <div className="top-part">
        <div className="field small">
          <Controller
            name="moodle"
            control={control}
            render={({ field: { value } }) => (
              <SelectFilter
                value={value}
                onChange={setMoodle}
                options={moodleFilter}
                label={t(_.global.form.moodleLabel)}
              />
            )}
          />
        </div>
        <div className="field small">
          <Controller
            name="group"
            control={control}
            render={({ field: { value } }) => (
              <MultiSelectFilter
                value={value}
                onChange={setSelectedGroups}
                data={selectedGroups}
                label={t(_.global.form.groupIdLabel)}
              />
            )}
          />
        </div>
        <div className="field small">
          <Controller
            name="createdDate"
            control={control}
            render={({ field: { value } }) => (
              <DatePicker
                value={value}
                onChange={setSelectedDateCreate}
                label={t(_.global.form.createLabel)}
                type="create"
              />
            )}
          />
        </div>
        <div className="field small">
          <Controller
            name="connectDate"
            control={control}
            render={({ field: { value } }) => (
              <DatePicker
                value={value}
                onChange={setSelectedDateConnect}
                label={t(_.global.form.connectLabel)}
                type="connect"
              />
            )}
          />
        </div>
      </div>
      <div className="active-filter">
        {!!filterActive.length
          ? filterActive.map(f => {
              return (
                <span className="puce-active-filter" key={f.label}>
                  {f.label.includes('Yes') || f.label.includes('No')
                    ? `Moodle : ${f.label}`
                    : f.label}
                  <span
                    className="icon-remove-filter"
                    onClick={e => {
                      e.stopPropagation();
                      handleRemoveFilter(f);
                    }}
                  >
                    &#10005;
                  </span>
                </span>
              );
            })
          : null}
      </div>
    </div>
  );
}
